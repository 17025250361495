import React from 'react'
import {Link} from 'react-router-dom';
import './notFound.css'

export default function NoFound() {
    return (
        <div className='not-found-content'>
            <h1 className='not-found-title'>404</h1>
            <p className='not-found-text'>Malheureusement, nous ne pouvons pas trouver cette page !</p>
            <Link className='not-found-link' to="/">Retourner à la page d'accueil</Link>
        </div>
    )
}
