import React from "react";
import "./header.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function ReminderContent() {

    return (
        <div className='btn__content'>
            <a
                className='reminder-whatsapp d-flex align-items-center'
                target="_blank"
                rel='noreferrer'
                href={"https://api.whatsapp.com/send?phone=242068312688&text=Bonjour,Lefleuve CG \nJe viens au pres de vous pour solliciter votre aide.Pouvez vous m'aider?&app_absent=0"}
            >
                <p className="header-whatsapp-content">
                    <span className="whatsapp-msg">Laissez nous un message sur </span>
                </p>
                <WhatsAppIcon className="whatsapp-ico"/>
            </a>
        </div>
    );
}
