import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {Image} from "react-bootstrap";
import "./parteners.css";

export default function Parteners({partners}) {
    return (
        <div className='d-flex justify-content-center flex-column align-items-center mt-md-5 mb-5'>
            <h1 className=''> Nos partenaires </h1>{" "}
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    "& > :not(style)": {
                        m: 1
                    },
                }}
            >
                {
                    partners && partners.map(({id, url, company, isActive}) => isActive && (
                        <Paper key={id} className='m-4 m-xs-2' elevation={3}>
                            <Image
                                className='partener__img'
                                src={url}
                                alt={company}
                                title={company}
                            />
                        </Paper>
                    ))
                }
            </Box>{" "}
            <p className='mt-md-2 text-center'>
                Le <span className='fw-bold'> Partenariat </span> est notre priorité.
                Souheteriez - vous être les notre ?
            </p>
        </div>
    );
}
