import React, {memo} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Pagination} from "swiper";
import {Card, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import "./similarAnnoucement.css";

SwiperCore.use([Pagination]);

const SimilarProduct = ({data}) => {
    return (
        <div>
            {data.length > 1 && <h2 className="mb-3">Les annonces similaires</h2>}
            <Swiper
                breakpoints={{
                    300: {
                        slidesPerView: 2,
                    },
                    500: {
                        slidesPerView: 3,
                    },
                    1000: {
                        slidesPerView: 4,
                    },
                }}
                spaceBetween={18}
                freeMode={true}
                className="mySwiper"
            >
                {data?.length > 1 &&
                    data?.map(
                        ({
                             id,
                             images,
                             price,
                             bestSeller,
                             discount,
                             productName,
                             sub_category,
                             brand,
                         }) => (
                            <SwiperSlide key={id}>
                                <Col key={id} className="top-house-col mb-3">
                                    <Link className="top-house-link" to={`/${id}`}>
                                        <Card className="card-item position-relative">
                                            <div>
                                                <Card.Img
                                                    className="card-image"
                                                    variant="top"
                                                    alt={`image-${id}`}
                                                    src={images[0]}
                                                />
                                                {bestSeller && (
                                                    <Card.Text className="text-small top-sale position-absolute">
                                                        meilleures ventes
                                                    </Card.Text>
                                                )}
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="fw-bolder small-text">
                                                    {price * (1 - (discount || 0) / 100)} XAF
                                                </Card.Title>
                                                {discount !== 0 && (
                                                    <Card.Title className="fw-bolder small-text discount-price">
                                                        Prix initial{" "}
                                                        <span className="initial-price">{price} XAF</span>
                                                        <span className="discount">{discount} %</span>
                                                    </Card.Title>
                                                )}
                                                <div className="d-sm-block mb-2 mt-2">
                                                    <Card.Text
                                                        className="mb-sm-2 small-text cut-text"
                                                        title={productName}
                                                    >
                                                        {productName}{" "}
                                                    </Card.Text>{" "}
                                                    <Card.Text className="text-small text-primary small-text mb-sm-2">
                                                        {sub_category}
                                                    </Card.Text>{" "}
                                                </div>
                                                {" "}
                                                <Card.Text className="small-text mb-3">
                                                    {brand?.toUpperCase()}
                                                </Card.Text>
                                            </Card.Body>{" "}
                                        </Card>
                                    </Link>
                                </Col>
                            </SwiperSlide>
                        )
                    )}
            </Swiper>
        </div>
    );
};

export default memo(SimilarProduct);
