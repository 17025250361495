import React from "react";
import {Col, Row} from "react-bootstrap";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import {Link} from "react-router-dom";

export default function MainFooter() {
    return (
        <div className='footer-container shadow-lg pb-4'>
            <Row xs={1} md={4}>
                <Col>
                    <h4>
                        <Link to='/' className='footer__links fw-bold'>
                            Accueil
                        </Link>
                    </h4>
                    <p>
                        <Link to='/store' className='footer__links'>
                            Services
                        </Link>
                    </p>
                    <p>
                        <Link to='/about' className='footer__links'>
                            A propos de nous
                        </Link>
                    </p>
                    <p>
                        <Link to='/contact' className='footer__links'>
                            Contacter nous
                        </Link>
                    </p>
                    <p className="partenariat__content">
                        <Link to='/partenariat' className='footer__links partenariat__link'>
                            Partenariat
                        </Link>
                    </p>
                </Col>
                <Col>
                    <h4 className="fw-bold">Nos contacts</h4>
                    <p><a className="footer__links" href="tel:05 579 75 81">+242 05 579 75 81</a></p>
                    <p><a className="footer__links" href="tel:06 831 26 88">+242 06 831 26 88</a></p>
                    <p><a className="footer__links" href="mailto:lefleuve.cg@gmail.com">lefleuve.cg@gmail.com</a></p>
                    <p><a className="footer__links" href="mailto:lefleuve.cg@outlook.com">lefleuve.cg@outlook.com</a>
                    </p>
                </Col>
                <Col>
                    <h4 className="fw-bold">Réseaux sociaux</h4>
                    <p>
                        <a
                            className='footer-contact__link'
                            target="_blank"
                            rel='noreferrer'
                            href='https://api.whatsapp.com/send?phone=242068312688'
                        >
                            <WhatsAppIcon/> Whatsapp
                        </a>
                    </p>
                    <p>
                        <a target="_blank" rel='noreferrer' className='footer-contact__link'
                           href='https://www.instagram.com/lefleuvecg/'>
                            <InstagramIcon/> Instagram
                        </a>
                    </p>
                    <p>
                        <a target="_blank" rel='noreferrer' className='footer-contact__link'
                           href='https://www.facebook.com/lefleuve.cg'>
                            <FacebookIcon/> Facebook
                        </a>
                    </p>
                </Col>
                <Col>
                    <h4 className="fw-bold">Addresse(s)</h4>
                    <p>115 bis RueMadingou, Av Bouetambongo, Bz</p>
                </Col>
            </Row>
            <Row className="mt-md-5">
                <hr className="w-75 ms-auto me-auto"/>
                <p className="text-center">Copyright © {new Date().getFullYear()} Lefleuve - CG</p>
            </Row>
        </div>
    );
}
