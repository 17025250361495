import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId,
};

// Initialize Firebase

export const AddDataInFirebase = async (collectionName, data) => {
    const collectionRef = firestore.collection(collectionName);
    const batch = firestore.batch();
    data.forEach((obj) => {
        const newDocRef = collectionRef.doc();
        batch.set(newDocRef, obj);
    });
    return await batch.commit();
};

export const getCollectionInFireBase = async (collections) => {
    const eventCollection = collections.docs.map((doc) => {
        return {id: doc.id, ...doc.data()};
    });

    return await eventCollection;
};


export const setFormToFireBase = async (data, collectionName) => {
    const createdAt = new Date();
    return await firestore.collection(collectionName).add({
        ...data,
        createdAt,
    });
};

export const addViewCollection = async (id, incremntCount) => {
    await firestore.doc(`userView/${id}`)
        .set({countView: incremntCount, id}, {merge: true});
}

firebase.initializeApp(firebaseConfig);
export const authentification = firebase.auth();
export const firestore = firebase.firestore();

export default firebase;
