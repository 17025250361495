import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchFlatDataAsync} from "./fetchData";

export const getGameDataAsync = createAsyncThunk(
    "getGameDataAsync",
    async () => {
        const modes = await fetchFlatDataAsync("toy-and-game");
        return modes?.sort(() => 0.51245 - Math.random());
    }
);

const initialState = {
    games: [],
};

export const gameDataSlice = createSlice({
    name: "game",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getGameDataAsync.fulfilled, (state, action) => {
            state.games = action.payload.filter((d) => d.isActive);
        });
    },
});

export default gameDataSlice.reducer;
