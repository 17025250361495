export const CITIES = [
    {
        id: 1,
        city: "Brazzaville",
        isActive: true,
        arrs : [
            {
                id: 1,
                arr: "Ouenze"
            },
            {
                id: 2,
                arr: "Talangai"
            },
            {
                id: 3,
                arr: "Poto-poto"
            },
            {
                id: 4,
                arr: "Makelekele"
            },
            {
                id: 5,
                arr: "Moungali"
            },
            {
                id: 6,
                arr: "Ndjiri"
            },
            {
                id: 7,
                arr: "Nkintele"
            },
            {
                id: 8,
                arr: "Bacongo"
            }
        ]
    },
    {
        id: 2,
        city: "Pointe-noire",
        isActive: false,
        arrs : [
            {
                id: 1,
                arr: "Lumumba"
            },
            {
                id: 2,
                arr: "Mvoumvou"
            },
            {
                id: 3,
                arr: "Tie-tie"
            },
            {
                id: 4,
                arr: "Louandjili"
            },
            {
                id: 5,
                arr: "Mpoukou"
            },
            {
                id: 6,
                arr: "Ngoyo"
            }
        ]
    }
]

export const DELIVERYPRICE = [
    {
        id: 1,
        arr: "Ouenze",
        price: "750"
    },
    {
        id: 2,
        arr: "Talangai",
        price: "750"
    },
    {
        id: 3,
        arr: "Poto-poto",
        price: "450"
    },
    {
        id: 4,
        arr: "Makelekele",
        price: "450"
    },
    {
        id: 5,
        arr: "Moungali",
        price: "450"
    },
    {
        id: 6,
        arr: "Ndjiri",
        price: "950"
    },
    {
        id: 7,
        arr: "Nkintele",
        price: "950"
    },
    {
        id: 8,
        arr: "Bacongo",
        price: "950"
    }
]

export const categoryItems = {
    "mode" : {
        categories : [
            {
                id: 1,
                category: "costume",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2Fveste-costume-noir-anis-230g-1-bouton-ref2anis.jpg?alt=media&token=68cc0b88-b819-4cca-acb4-b9024abfdda8"
            },
            {
                id: 2,
                category: "Sandale",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2Fsoul-caramel1_UMBasala.jpeg?alt=media&token=b78680c6-72b6-4f04-84c2-a1bb765d7ef7"
            },
            {
                id: 3,
                category: "Soutien-gorge",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2F71bGMj%2BmTaL._AC_SX679_.jpg?alt=media&token=4ba45763-4bef-467c-a82c-7f64fdb633e6"
            },
            {
                id: 4,
                category: "Chaussure",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2Fmarques-de-chaussures-homme-scaled.jpg?alt=media&token=a191df59-8a4a-4bc7-8551-4642e29be918"
            },
            {
                id: 5,
                category: "Pyjama",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2F14-0201.jpg?alt=media&token=a17a639b-454e-4558-b1e5-f07da41211d0"
            },
            {
                id: 6,
                category: "Chausson",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2Fchausson-chaussette-enfant_2000x.jpg?alt=media&token=683f2f61-1c31-486c-a631-87b91476eff5"
            },
            {
                id: 61,
                category: "Sac",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2Fsac.jpg?alt=media&token=2aea4a1b-4af5-48ee-ab38-3ed603d514e3"
            },
            {
                id: 7,
                category: "Robe",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2Fmariejune-robe-soiree-asymetrique-avec-fleur-royal_blue-1.jpg?alt=media&token=b56008dd-f3c2-46ce-b476-0960b6ef310a"
            },
            {
                id: 8,
                category: "Polo",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2F18830877_500_A.jpg?alt=media&token=06bb45e5-4a54-4a7c-a122-a5369ae8b860"
            },
            {
                id: 9,
                category: "Pantalon",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2F1680213793_PANTALON%20FRENTE.png?alt=media&token=e480b737-4bec-4787-8480-e13919b1da8a"
            },
            {
                id: 10,
                category: "Chemise",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2Factive-stretch-shirt.png?alt=media&token=0e73612b-de62-4d33-893f-dd06f47213c6"
            },
            {
                id: 11,
                category: "Sous-vêtement",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2Fsous-vetement-coton.jpg?alt=media&token=2e81772f-7864-4e61-97dd-63fe54178154"
            },
            {
                id: 12,
                category: "Bonnet",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2F164e33f11d31cc9417e16f2fd05092e5.jpg_720x720q80.jpg?alt=media&token=2997b15d-73b9-44ff-a4eb-48c101a7402c"
            },
            {
                id: 13,
                category: "Ensemble",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/category%2FEnsemble.jpg?alt=media&token=b30cc387-7829-4981-837e-2105505ca2ca"
            },
            {
                id: 14,
                category: "Basket",
                image: "https://firebasestorage.googleapis.com/v0/b/lefleuve-cg.appspot.com/o/e-commerce%2F465d9e77-3541-4129-9d97-976134379ebb.jpg?alt=media&token=4738d9ed-7f23-45c9-bf0e-d13829df0eef"
            }
        ],
    },
    "jeux-et-jouets" : {
        categories: null
    }
}

export const categoryOfArticles = {
    "mode":"mode",
    "jeux-et-jouets": "toy-and-game"
}


export const OurService = [
    {
        id: 1,
        path: "/store",
        pathName: "Maisons et Terrains"
    },
    {
        id: 2,
        path: "/products/Mode",
        pathName: "Mode"
    },
    {
        id: 3,
        path: "/products/toy-and-game",
        pathName: "Jeux et Jouets"
    },
    {
        id: 4,
        path: "/products/beauty-and-parfum",
        pathName: "Beauté, hygiène et Parfum"
    },
    {
        id: 5,
        path: "/products/cuisine-et-maison",
        pathName: "Cuisine et maison"
    }
]