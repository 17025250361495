import React, {lazy, Suspense, useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Container} from "react-bootstrap";
import Footer from "./components/footer/footer";
import {useDispatch, useSelector} from "react-redux";
import {getDataAsync} from "./toolkit/data/dataSlice";
import {geViewDataAsync} from "./toolkit/data/viewSlice";
import {getScrollingImageAsync} from "./toolkit/data/scrollingImageSlice";
import {gePartnerDataAsync} from "./toolkit/data/partnerSlice";
import Home from "./components/main/home/Home";
import NavBar from "./components/header/NavBar";
import NoFound from "./components/NoFound/NoFound";
import LoadingPage from "./components/Loading/LoadingPage";
import Feedback from "./components/feedback/Feedback";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {getCookie} from "./components/cookie/getCookie";
import {setCookie} from "./components/cookie/setCookie";
import {getQuantity} from "./toolkit/data/addToCartSlice";
import {getModeDataAsync} from "./toolkit/data/modeSlice";
import {getGameDataAsync} from "./toolkit/data/gameSlice";
import {EarnPublicity} from "./components/main/earnPublicity/EarnPublicity";
import {getCollectionData} from "./toolkit/data/findCollectionSlice";
import EcommerceArticleDetail from "./components/detail/e-commerce/EcommerceArticleDetail";


const LazyStore = lazy(() => import("./components/main/store/Store"))
const LazyContact = lazy(() => import("./components/main/contact/Contact"));
const LazyPartenariat = lazy(() => import("./components/main/partenariat/Partenariat"));
const LazyCardDetail = lazy(() => import("./components/detail/CardDetail"));
const LazyAbout = lazy(() => import("./components/main/about/About"));
const LazyCardProduct = lazy(()=> import("./components/main/store/CardProduct"));
const LazyOrder = lazy(()=> import("./components/order/Order"));
const LazyEcommerceArticleDetail = lazy(()=> import("./components/detail/e-commerce/EcommerceArticleDetail"));
const LazyCheckOut = lazy(()=> import("./components/Pay/OrderPayment"));


function App() {
    const dispatch = useDispatch();
    const {status} = useSelector((state) => state?.flatData);
    const [showed, setShowed] = useState(false);
    const cookie = getCookie("showIcon");

    useEffect(() => {
        dispatch(geViewDataAsync());
        dispatch(getScrollingImageAsync());
        dispatch(gePartnerDataAsync());
        dispatch(getDataAsync());
        dispatch(getQuantity());
        dispatch(getModeDataAsync());
        dispatch(getGameDataAsync());
        dispatch(getCollectionData({collectionName:"beauty-and-parfum"}))
        setCookie("showIcon", true, 180);
    }, [dispatch]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!Boolean(cookie)) {
                setShowed(true);
            }
        }, 5000);
        return () => clearTimeout(timeout);
    }, [cookie]);

    if (status !== "idle") return <LoadingPage/>;

    const handleClickModal = () => {
        setShowed((isShowed) => !isShowed);
    };

    return (
        <Container fluid className="position-relative">
            <BrowserRouter>
                <NavBar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                        <Route path="/store" element={
                            <Suspense fallback={<LoadingPage />}>
                                <LazyStore/>
                            </Suspense>}/>
                        <Route path="/contact" element={
                            <Suspense fallback={<LoadingPage />}>
                               <LazyContact/>
                            </Suspense>
                            }/>
                        <Route path="/earn-money" element={
                            <Suspense fallback={<LoadingPage />}>
                              <EarnPublicity/>
                            </Suspense>
                            }/>
                        <Route path="/about" element={
                            <Suspense fallback={<LoadingPage />}>
                               <LazyAbout/>
                            </Suspense>
                            }/>
                        <Route path="/partenariat" element={
                            <Suspense fallback={<LoadingPage />}>
                               <LazyPartenariat/>
                            </Suspense>
                            }/>
                        <Route path="/store/:id" element={
                            <Suspense fallback={<LoadingPage />}>
                            <LazyCardDetail/>
                            </Suspense>
                            }/>
                        <Route path="/products/:category" element={
                            <Suspense fallback={<LoadingPage />}>
                               <LazyCardProduct/>
                            </Suspense>
                            }/>
                        <Route index path="/products/Mode/:id" element={
                            <Suspense fallback={<LoadingPage />}>
                              <EcommerceArticleDetail/>
                            </Suspense>
                            }/>
                        <Route index path="/products/beauty-and-parfum/:id" element={
                            <Suspense fallback={<LoadingPage />}>
                            <LazyEcommerceArticleDetail/>
                            </Suspense>
                            }/>
                        <Route
                            index
                            path="/products/toy-and-game/:id"
                            element={<LazyEcommerceArticleDetail/>}
                        />
                        <Route path="/product/panier" element={
                            <Suspense fallback={<LoadingPage />}>
                            <LazyOrder/>
                            </Suspense>
                            }/>
                        <Route path="/store/info/checkout" element={
                            <Suspense fallback={<LoadingPage />}>
                            <LazyCheckOut/>
                            </Suspense>
                            }/>
                    <Route path="*" exact element={<NoFound/>}/>
                </Routes>
                {showed ? <Feedback handleClickModal={handleClickModal}/> : null}
                {!showed ? (
                    <div title="Demarrer Whatsapp" className="whatsapp-content">
                        <a
                            href="https://api.whatsapp.com/send?phone=242068312688"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <WhatsAppIcon className="my-icon-whatsapp"/>
                        </a>
                    </div>
                ) : null}
                <Footer/>
            </BrowserRouter>
        </Container>
    );
}

export default App;
