import * as React from "react";
import {NavLink} from "react-router-dom";
import {Container, Image, Nav, Navbar, NavDropdown} from "react-bootstrap";
import SwiperSlideHeader from "../swiper/SwiperSlide";
import {useSelector} from "react-redux";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import {PublicityContent} from "../main/publicity/PublicityContent";
import {OurService} from "../../assets/consts";

export default function NavBar() {
    const images = useSelector((state) => state.images.mainImages);
    const quantity = useSelector((state) => state?.addToCart?.quantity);
    return (
        <div className="position-relative lefeuve-nav-bar">
            <PublicityContent/>
            <Navbar collapseOnSelect expand="lg" bg="white" variant="dark">
                <Container className="position-relative">
                    <NavLink to="/" className="nav-text navbar-brand h-100">
                        <Image
                            loading="lazy"
                            className="logo__img"
                            src="/lefleuve-cg-logo.svg"
                            alt="lefleuve-logo-alt"
                        />
                    </NavLink>
                    <Nav className="position-relative btn-panier-mobile">
                        <NavLink className="nav-text nav-link btn" to="/product/panier">
                            <LocalGroceryStoreIcon/>
                            <span className="quantity-product">{quantity}</span>
                        </NavLink>
                    </Nav>
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        className="text-black"
                        placement="end"
                    />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="m-auto">
                            <NavLink
                                to="/"
                                className={({isActive}) =>
                                    "nav-text nav-link " + (isActive ? "selected-link" : "")
                                }
                            >
                                Accueil
                            </NavLink>
                            <NavDropdown
                                title="Services"
                                id="basic-nav-dropdown"
                                className="nav-text-link fw-bold"
                            >
                                {
                                    OurService?.map(o => (
                                        <NavDropdown.Item key={o.id} href={o.path} className="nav-text nav-link ">
                                            {o.pathName}
                                        </NavDropdown.Item>
                                    ))
                                }
                            </NavDropdown>
                            <NavLink
                                to="/about"
                                className={({isActive}) =>
                                    "nav-text nav-link " + (isActive ? "selected-link" : "")
                                }
                            >
                                A propos de nous
                            </NavLink>
                            <NavLink
                                to="/contact"
                                className={({isActive}) =>
                                    "nav-text nav-link " + (isActive ? "selected-link " : "")
                                }
                            >
                                Contacter nous
                            </NavLink>
                            <NavLink
                                className="nav-text nav-link btn__paternariat btn"
                                to="/partenariat"
                            >
                                Partenariat
                            </NavLink>
                        </Nav>
                        <Nav className="position-relative btn-panier">
                            <NavLink className="nav-text nav-link btn" to="/product/panier">
                                <LocalGroceryStoreIcon/>
                                <span className="quantity-product">{quantity}</span>
                            </NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <SwiperSlideHeader images={images}/>
        </div>
    );
}
