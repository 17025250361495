import {createSlice} from "@reduxjs/toolkit";
import {fetchFlatDataAsync} from "./fetchData";


const initialState = {
    data: [],
    isLoading: true
}


export const findCollectionSlice = createSlice({
    name: "collection",
    initialState,
    reducers: {
        getCollectionData: async (state, action) => {
            const {collectionName} = action.payload;
            const articles = await fetchFlatDataAsync(collectionName);
            const data = await articles
            return {
                ...state,
                data,
                isLoading: false
            }
        }
    }
})

export const {getCollectionData} = findCollectionSlice.actions
export default findCollectionSlice.reducer