import React from "react";
import Modal from "../main/modal/Modal";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function Feedback({handleClickModal}) {
    return (
        <Modal handleClickModal={handleClickModal}>
            <div className="d-flex p-4 justify-content-center gap-1 align-items-center">
                <h3 className="text-center">Ecrivez nous sur Whatsapp</h3>
                <div title="Aller sur Whatsapp" className="whatsapp-modal-content">
                    <a
                        href="https://api.whatsapp.com/send?phone=242068312688"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <WhatsAppIcon className="my-icon-whatsapp"/>
                    </a>
                </div>
            </div>
        </Modal>
    );
}
