import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchFlatDataAsync} from "./fetchData";

export const getDataAsync = createAsyncThunk("getDataAsync", async () => {
    const lands = await fetchFlatDataAsync("flatCollection");
    const rents = await fetchFlatDataAsync("rentCollection");
    return [...rents, ...lands].sort(() => 0.51245 - Math.random());
});

const initialState = {
    status: "idle",
    data: [],
};

export const dataSlice = createSlice({
    name: "get_data",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDataAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getDataAsync.fulfilled, (state, action) => {
                state.data = action.payload.filter((d) => d.isActive);
                state.status = "idle";
            });
    },
});

export default dataSlice.reducer;
