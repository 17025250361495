import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchFlatDataAsyncById} from "./fetchData";

export const getDataAsyncById = createAsyncThunk(
    "getDataAsyncById",
    async (collection, id) => {
        return await fetchFlatDataAsyncById(collection, id);
    }
);

const initialState = {
    dataDetail: {},
    status: "idle",
};

export const detailItemSlice = createSlice({
    name: "detail",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDataAsyncById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getDataAsyncById.fulfilled, (state, action) => {
                state.dataDetail = action.payload;
            });
    },
});

export default detailItemSlice.reducer;
