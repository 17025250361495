import {firestore, getCollectionInFireBase} from "../../firebase/config";

export const fetchFlatDataAsync = async (collectionName) => {
    const collectionRef = firestore.collection(collectionName);
    return await collectionRef.get().then(async (snapshot) => {
        return await getCollectionInFireBase(snapshot);
    });
};

export const fetchFlatDataAsyncById = async (collectionName, id) => {
    const collectionRef = firestore.collection(collectionName).doc(id);
    return await collectionRef.get().then(async (snapshot) => {
        return await getCollectionInFireBase(snapshot);
    });
};

