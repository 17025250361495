import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchFlatDataAsync} from "./fetchData";

export const geViewDataAsync = createAsyncThunk("fetchViewData", async () => {
    return await fetchFlatDataAsync("userView");
});

const initialState = {
    status: "idle",
    views: [],
};

export const viewSlice = createSlice({
    name: "viewUser",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(geViewDataAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(geViewDataAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.views = action.payload;
            });
    },
});

export default viewSlice.reducer;
