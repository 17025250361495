import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    showMagnifier: false,
    magnifierHeight: 0,
    magnifieWidth: 0,
    y: 0,
    x: 0,
    imgWidth: 0,
    zoomLevel: 0,
    imgHeight: 0,
    src:null
}

export const magnifierSlice = createSlice({
    name: "magnifier",
    initialState,
    reducers: {
        setMagnifierData:  (state, action) => {
            const {showMagnifier,magnifierHeight,magnifieWidth,x,y,imgWidth,zoomLevel,imgHeight, src} = action.payload;
            return{
                ...state,
                showMagnifier,
                magnifierHeight,
                magnifieWidth,
                x,
                y,
                imgWidth,
                zoomLevel,
                imgHeight,
                src
            }
        }
    }
})

export const {setMagnifierData} = magnifierSlice.actions

export default magnifierSlice.reducer