import React from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function TopAppartment({data, type, handleClickView}) {
    return data.length > 0 && <Container>
        <Row>
            <h1 className="mt-5 mb-2 ms-0">{type}</h1>
        </Row>
        <Row xs={2} md={4} className="top-house-row">
            {data &&
                data?.map(
                    ({
                         id,
                         price,
                         sector,
                         images,
                         category,
                         rooms,
                         area,
                         createDate,
                         createdTime,
                     }) => (
                        <Col
                            key={id}
                            onClick={() => handleClickView(id)}
                            className="top-house-col mb-3"
                        >
                            <Link
                                className="top-house-link"
                                to={`/store/${id}`}
                            >
                                <Card className="card-item">
                                    <div>
                                        <Card.Img
                                            className="card-image"
                                            variant="top"
                                            alt={`image-${id}`}
                                            src={images[0]}
                                        />
                                    </div>
                                    <Card.Body>
                                        <Card.Title className="fw-bolder small-text">
                                            {" "}
                                            {price} XAF{" "}
                                        </Card.Title>{" "}
                                        <div className="d-sm-block mb-2 mt-2">
                                            <Card.Text className="mb-sm-2 small-text">
                                                {sector}{" "}
                                            </Card.Text>{" "}
                                            <Card.Text className="text-small text-primary small-text mb-sm-2">
                                                {category}
                                            </Card.Text>{" "}
                                        </div>
                                        {" "}
                                        {category === "Maison à louer" && (
                                            <Card.Text className="small-text">
                                                {" "}
                                                {rooms} chambres{" "}
                                            </Card.Text>
                                        )}{" "}
                                        {category === "Bureau à louer" && (
                                            <Card.Text className="small-text">
                                                {" "}
                                                {rooms} piece(s){" "}
                                            </Card.Text>
                                        )}{" "}
                                        {category === "Terrain à vendre" && (
                                            <Card.Text className="small-text">
                                                {" "}
                                                {area} m2{" "}
                                            </Card.Text>
                                        )}{" "}
                                        <Card.Text>
                                            <small className="text-muted text-center small-text">
                                                Publié le {createDate} {createdTime}
                                            </small>{" "}
                                        </Card.Text>
                                    </Card.Body>{" "}
                                </Card>
                            </Link>
                        </Col>
                    )
                )}
        </Row>
    </Container>;
}
