import React from "react";

export default function Modal({handleClickModal, children}) {
    return (
        <div className="detail-modal" onClick={handleClickModal}>
            <div className="modal-dialog-detail">
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    {children}
                </div>
            </div>
        </div>
    );
}
