import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addToCart, getQuantity} from "../../../toolkit/data/addToCartSlice";
import ECommerceDetail from "./ECommerceDetail";
import React, {useEffect, useState} from "react";
import {articleByCategory} from "../../../toolkit/data/findByCategorySlice";
import './../cardDetail.css'

const EcommerceArticleDetail = () => {
    const params = useParams();
    const location = useLocation()
    const byCategory = useSelector((state) => state?.byCategory);
    const [articles, setArticles] = useState([])
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        const collectionName = location.pathname.split("/")[2].toLowerCase();
        dispatch(articleByCategory({category: "", collectionName}))
    }, [dispatch, location.pathname]);

    useEffect(() => {
        let isCancelled = false;
        (async () => {
            const userArticles = await byCategory
            if (!isCancelled) {
                setArticles(userArticles?.currentArticles)
            }
        })()
        return () => {
            isCancelled = true;
        };
    }, [params?.id, byCategory]);
    const handleBuyBtn = () => {
        const article = articles?.find(a => a.id === params?.id);
        navigate("/product/panier");
        dispatch(addToCart({...article, owner: null}));
        dispatch(getQuantity());
    };

    const handleClickAddToCart = () => {
        const article = articles?.find(a => a.id === params?.id);
        dispatch(addToCart({...article, owner: null}));
        dispatch(getQuantity());
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };
    return <ECommerceDetail data={articles?.find(a => a.id === params?.id)} handleClickAddToCart={handleClickAddToCart}
                            handleBuyBtn={handleBuyBtn} items={articles}/>
}

export default EcommerceArticleDetail