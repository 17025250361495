import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchFlatDataAsync} from "./fetchData";

export const getModeDataAsync = createAsyncThunk(
    "getModeDataAsync",
    async () => {
        const modes = await fetchFlatDataAsync("mode");
        return modes?.sort(() => 0.51245 - Math.random());
    }
);

const initialState = {
    modes: [],
};

export const modeDataSlice = createSlice({
    name: "mode",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getModeDataAsync.fulfilled, (state, action) => {
            state.modes = action.payload.filter((d) => d.isActive);
        });
    },
});

export default modeDataSlice.reducer;
