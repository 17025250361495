import {useState} from "react"
import {useDispatch} from "react-redux";
import {setMagnifierData} from "../toolkit/data/magnifierSlice";

export function ImageMagnifier({
                            src,
                            width,
                            height,
                            magnifierHeight = 150,
                            magnifieWidth = 150,
                            zoomLevel = 2
                        }) {
    const dispatch = useDispatch()
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);
    return (
        <div
            style={{
                position: "relative",
                height: height,
                width: width,
                display: "flex",
            }}
            className="content-image-magnifier"
        >
            <img
                src={src}
                style={{ height: height, width: width }}
                onMouseEnter={(e) => {
                    const elem = e.currentTarget;
                    const { width, height } = elem.getBoundingClientRect();
                    setSize([width, height]);
                    setShowMagnifier(true);
                }}
                className="img-magnifier"
                onMouseMove={(e) => {
                    const elem = e.currentTarget;
                    const { top, left } = elem.getBoundingClientRect();
                    const x = e.pageX - left - window.pageXOffset;
                    const y = e.pageY - top - window.pageYOffset;
                    setXY([x, y]);
                    if (window.matchMedia("(min-width: 600px)").matches){
                    dispatch(setMagnifierData({showMagnifier,magnifierHeight,magnifieWidth,x,y,imgWidth,zoomLevel,imgHeight,src}))
                    }
                }}
                onMouseLeave={() => {
                    setShowMagnifier(false);
                    dispatch(setMagnifierData({showMagnifier: false,magnifierHeight,magnifieWidth,x,y,imgWidth,zoomLevel,imgHeight, src}))
                }}
                alt={"img"}
            />

            <div
                style={{
                    display: showMagnifier && window.matchMedia("(min-width: 600px)").matches ? "" : "none",
                    position: "absolute",
                    pointerEvents: "none",
                    height: `${magnifierHeight}px`,
                    width: `${magnifieWidth}px`,
                    top: `${y - magnifierHeight / 2}px`,
                    left: `${x - magnifieWidth / 2}px`,
                    opacity: "1",
                    border: "1px solid lightgray",
                    backgroundImage: 'url("https://m.media-amazon.com/images/G/03/apparel/rcxgs/tile._CB483369910_.gif")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: `${magnifieWidth}px ${
                        magnifierHeight
                    }px`,
                }}
            ></div>
        </div>
    );
}