import React from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function TopProduct({data, type, link}) {
    return data.length &&
        <Container>
            <Row>
                <h1 className="mt-5 mb-2 ms-0">{type}</h1>
            </Row>
            <Row xs={2} md={4} className="top-house-row">
                {data &&
                    data
                        ?.slice(0, 4)
                        ?.map(
                            ({
                                 id,
                                 price,
                                 images,
                                 sub_category,
                                 brand,
                                 productName,
                                 bestSeller,
                                 discount,
                             }) => (
                                <Col key={id} className="top-house-col mb-3">
                                    <Link className="top-house-link" to={`${link}/${id}`}>
                                        <Card className="card-item position-relative">
                                            <div className="position-relative">
                                                <Card.Img
                                                    className="card-image"
                                                    variant="top"
                                                    alt={`image-${id}`}
                                                    src={images[0]}
                                                />
                                                {bestSeller === "true" && (
                                                    <Card.Text className="text-small top-sale position-absolute">
                                                        meilleures ventes
                                                    </Card.Text>
                                                )}
                                                {+discount > 0 && (
                                                    <span className="discount-product discount">
                            {discount} %
                          </span>
                                                )}
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="fw-bolder small-text card-detail-name">
                                                    {+price * (1 - (discount || 0) / 100)} XAF
                                                    <span className="initial-price">
                            {+discount > 0 ? price + " XAF" : " "}
                          </span>
                                                </Card.Title>
                                                <div className="d-sm-block mb-2 mt-2">
                                                    <Card.Text
                                                        className="mb-sm-2 small-text cut-text"
                                                        title={productName}
                                                    >
                                                        {productName}{" "}
                                                    </Card.Text>{" "}
                                                    <Card.Text
                                                        className="text-small text-primary small-text mb-sm-2 cut-text">
                                                        {sub_category}
                                                    </Card.Text>{" "}
                                                </div>
                                                {" "}
                                                <Card.Text className="small-text brand-text">
                                                    {brand?.toUpperCase()}
                                                </Card.Text>
                                            </Card.Body>{" "}
                                        </Card>
                                    </Link>
                                </Col>
                            )
                        )}
            </Row>
            <Row className="d-flex justify-content-end">
                <Link to={link} className="btn btn-outline-info more-article fw-bold text-uppercase">
                    Voir plus d'article
                </Link>
            </Row>
        </Container>;
}
