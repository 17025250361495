import React from 'react'
import {Spinner} from 'react-bootstrap';
import './loadingPage.css';

export default function LoadingPage() {
    return (
        <div className='loading-page-content'>
            <iframe className='loading-img' title='logo'/>
            <div>
                <Spinner animation='border' size='sm'/>
                <span className='loading-page-text'>Chargement encours...</span>
            </div>
        </div>
    )
}
