import React from "react";
import "./header.css";

export default function HeaderAbout() {
    return (
        <div className="header-about-container shadow-lg">
            <div className='text__header w-75'>
                <h4 className='text-center fw-bold'>
                    L'excellence, c'est nous. Plus de 100 demandes satisfaites par semaine. Faites-nous confiance !
                </h4>
                <div className="d-flex justify-content-center">
                   <span className='text-center text-light bg-dark pe-1 px-1 rounded-1'>
                    Notre priorité est de répondre aux besoins de chacun, même des clients
                    les plus exigeants! Choisissez des professionnels!
                </span>
                </div>
            </div>
        </div>
    );
}
