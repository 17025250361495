import {createSlice} from "@reduxjs/toolkit";
import {addItemToCart} from "../utils";

const initialState = {
    carts: [],
    quantity: 0,
    total: 0,
    isDelivered: false,
};

export const addToCartSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            return {
                ...state,
                carts: addItemToCart(state.carts, action.payload),
            };
        },
        emptyCart: () => {
            return initialState
        },
        deleteToCart: (state, action) => {
            return {
                ...state,
                carts: state?.carts?.filter((cart) => cart.id !== action.payload),
                quantity: state.carts.reduce((acc, val) => acc + val.quantity, 0),
                total: state.carts.reduce(
                    (acc, val) =>
                        acc + val.price * (1 - (val?.discount || 0) / 100) * val.quantity,
                    0
                ),
            };
        },
        getQuantity: (state) => {
            return {
                ...state,
                quantity: state.carts.reduce((acc, val) => acc + val.quantity, 0),
                total: state.carts.reduce(
                    (acc, val) =>
                        acc + val.price * (1 - (val?.discount || 0) / 100) * val.quantity,
                    0
                ),
            };
        },
        incrementQuantity: (state, action) => {
            return {
                ...state,
                carts: state?.carts.map((cart) =>
                    cart?.id === action?.payload
                        ? {...cart, quantity: cart.quantity + 1}
                        : cart
                ),
            };
        },
        decrementQuantity: (state, action) => {
            return {
                ...state,
                carts: state?.carts.map((cart) =>
                    cart?.id === action?.payload
                        ? {...cart, quantity: cart.quantity - 1}
                        : cart
                ),
            };
        },
        setIsDelivered: (state, action) => {
            return {
                ...state,
                isDelivered: action.payload,
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    deleteToCart,
    addToCart,
    getQuantity,
    incrementQuantity,
    decrementQuantity,
    setIsDelivered,
    emptyCart
} = addToCartSlice.actions;

export default addToCartSlice.reducer;
