import {createSlice} from "@reduxjs/toolkit";
import {fetchFlatDataAsync} from "./fetchData";


const initialState = {
    currentArticles: [],
    status: 'idle',
}

export const ArticlesByCategory = createSlice({
    name: "articles",
    initialState,
    reducers: {
        articleByCategory: async (state, action) =>{
            const {category,collectionName} = action.payload
            const articles = await fetchFlatDataAsync(collectionName);
            const art = await articles
            const currentArticles = art
                ?.filter(a => a.isActive && a?.sub_category?.toLowerCase().includes(category.toLowerCase()))?.sort(() => 0.51245 - Math.random())
            return {
                ...state,
                currentArticles
            }
        },
        clearArticleByCategory: (state) => {
            return {
                ...state,
                currentArticles: []
            }
        }
    }
})

export const {articleByCategory,clearArticleByCategory} = ArticlesByCategory.actions
export default ArticlesByCategory.reducer;