import React from "react";
import MainFooter from "./MainFooter";
import "./footer.css";

export default function Footer() {
    return (
        <div>
            <MainFooter/>
        </div>
    );
}
