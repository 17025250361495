import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Keyboard, Mousewheel, Navigation, Pagination, Thumbs} from "swiper";
import "swiper/swiper-bundle.css";
import "./swiper.css";
import {ImageMagnifier} from "../../imageMagnifier/ImageMagnifier";
import {Image} from "react-bootstrap";

SwiperCore.use([Pagination, Navigation, Mousewheel, Thumbs]);

export default function SwiperDetail({images}) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    return (
        <div>
            <Swiper
                cssMode={true}
                navigation={false}
                pagination={false}
                mousewheel={true}
                keyboard={true}
                thumbs={{swiper: thumbsSwiper}}
                modules={[Navigation, Pagination, Mousewheel, Keyboard, Thumbs]}
                className="myHowIsWorkSwiper"
            >
                {images?.map((url, idx) => (
                    <SwiperSlide key={idx}>
                        <ImageMagnifier src={url} width={"100%"}/>
                    </SwiperSlide>
                ))}{" "}
            </Swiper>
            <Swiper
                cssMode={true}
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={5}
                freeMode={true}
                watchSlidesVisibility={true}
                watchSlidesProgress={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard, Thumbs]}
                className="myHowIsWorkSwiper mt-4"
            >
                {images?.map((url, idx) => (
                    <SwiperSlide key={idx} className="sub-img-content">
                        <Image className="sub-img" src={url}/>
                    </SwiperSlide>
                ))}{" "}
            </Swiper>
        </div>

    );
}
