export const EarnPublicity = () => {
    return (
        <div className="p-lg-4 mt-4">
            <h3>Gagnez 40% de plus en nous laissant faire louer votre maison !</h3>
            <div>
                <p>Vous êtes propriétaire d'une maison et vous souhaitez augmenter vos revenus sans effort
                    supplémentaire ? Nous avons la solution pour vous !</p>
                <p>Nous comprenons les défis auxquels sont confrontés les propriétaires lorsqu'il s'agit de trouver des
                    locataires de qualité. C'est pourquoi nous mettons à votre disposition une approche innovante qui
                    vous permet non seulement d'éviter les démarcheurs, mais aussi de bénéficier d'un avantage financier
                    significatif.</p>
                <h4 className="mt-4">Pourquoi Choisir <span className="text-secondary">Lefleuve CG</span>?</h4>
                <ul>
                    <li>Notre équipe expérimentée prend en charge tout le processus de location, de la recherche de
                        locataires à la gestion quotidienne. Fini les appels incessants et les visites inutiles !
                    </li>
                    <li>Nous utilisons des stratégies de marketing avancées pour promouvoir votre propriété et attirer
                        des locataires de qualité.
                    </li>
                    <li>En choisissant <span className="fw-bold">Lefleuve CG</span>, vous ne bénéficiez pas seulement
                        d'une tranquillité d'esprit, mais aussi d'avantages financiers. <span className="fw-bold">Recevez 40% du dernier mois de
                        loyers en tant que geste de reconnaissance de votre confiance envers nos services.</span>
                    </li>
                </ul>
                <h4 className="mt-4">Comment Ça Fonctionne :</h4>
                <ul>

                    <li>Contactez-nous pour discuter de vos besoins et de vos attentes en matière de location.</li>
                    <li>Nous prenons en charge la gestion complète du processus, de la publicité à la sélection des
                        locataires en passant par la signature des contrats.
                    </li>
                    <li>En remerciement de votre confiance, <span className="fw-bold">nous vous offrons 40% du dernier mois de loyers</span> lorsque
                        la
                        location est conclue.
                    </li>
                </ul>
                <p>
                    Optez pour <span className="fw-bold">Lefleuve CG</span> et découvrez une manière simple, rentable et
                    sans souci de louer votre propriété.
                </p>
                <div className="mt-5 mb-3">
                    <h5>Coordialement,</h5>
                    <h6 className="m-0 fw-bold">Lefleuve CG </h6>
                    <span className="m-0 text-decoration-underline">Votre partenaire de tous les jours</span>
                </div>
            </div>
        </div>
    )
}