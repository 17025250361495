import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchFlatDataAsync} from "./fetchData";

export const getScrollingImageAsync = createAsyncThunk("fetchImage", async () => {
    return await fetchFlatDataAsync("scrollingImages");
});

const initialState = {
    status: "idle",
    mainImages: [],
};

export const scrollingImage = createSlice({
    name: "images",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getScrollingImageAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getScrollingImageAsync.fulfilled, (state, action) => {
                state.mainImages = action.payload.filter(d => d.isActive);
                state.status = "idle";
            });
    },
});

export default scrollingImage.reducer;
