import React from "react";

export const MagnifierImageContent = ({magnifierData}) => {
    const {showMagnifier, magnifierHeight, magnifieWidth, x, y, imgWidth, zoomLevel, imgHeight, src} = magnifierData;
    return <div
        className="shadow"
        style={{
            position: "relative",
            width: "100%",
            zIndex: 100,
            height: "100%",
            overflow: "hidden"
        }}>
        <div
            style={{
                display: showMagnifier ? "" : "none",
                position: "absolute",
                pointerEvents: "none",
                height: `100%`,
                width: `100%`,
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                opacity: "1",
                border: "2px solid lightgray",
                backgroundColor: "white",
                backgroundImage: `url('${src}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: `${imgWidth * zoomLevel}px ${
                    imgHeight * zoomLevel
                }px`,
                backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
                backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
            }}
        ></div>
    </div>
}