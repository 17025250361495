import "./publicityContent.css"
import {Link} from "react-router-dom";

export const PublicityContent = () => {
    return (
        <div
            className="shadow text-center p-2 publicity-content d-lg-flex justify-content-center align-items-center gap-2">
            <p className="m-1 pub-paragraph">
                <span className="fw-bold">Gagnez directement </span>
                <span className="fw-bolder fs-4">40% </span>
                <span className="fw-bold">si vous êtes propriétaire d'une maison à louer! </span>
            </p>
            <Link to="/earn-money" className="btn-outline-light btn fs-6">Cliquez ici pour les details!</Link>
        </div>
    )
}