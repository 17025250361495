import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import BackTo from "../../backTo/BackTo";
import SwiperDetail from "../../swiper/SwiperDetail";
import SimilarProduct from "../../main/similarAnnouncements/SimilarProduct";
import {useSelector} from "react-redux";
import {ECommerceContent} from "./ECommerceContent";
import {MagnifierImageContent} from "./MagnifierImageContent";


export default function ECommerceDetail({data, handleBuyBtn, handleClickAddToCart, items}) {
    const magnifierData = useSelector(state => state.magnifier);
    const {showMagnifier} = magnifierData;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className="mt-5 mb-5 product-detail">
            <Row>
                <BackTo/>
            </Row>
            <Row>
                <h6 className="mb-2 mt-4">No. {data?.ref}</h6>
            </Row>
            <Row xs={1} className="product-detail-row">
                <Col>
                    <SwiperDetail images={data?.images}/>
                </Col>
                <Col>
                    {
                        !showMagnifier ? <ECommerceContent
                                data={data}
                                handleBuyBtn={handleBuyBtn}
                                handleClickAddToCart={handleClickAddToCart}/> :
                            <MagnifierImageContent magnifierData={magnifierData}/>
                    }
                </Col>
            </Row>
            <Row className="mt-5">
                <SimilarProduct
                    data={items?.filter((pr) => pr.sub_category === data?.sub_category)}
                />
            </Row>
        </Container>
    );
}
