import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, Pagination} from "swiper";
import "swiper/swiper-bundle.css";
import {Image} from "react-bootstrap";
import "./swiper.css";
import HeaderAbout from "../header/headerAbout";
import ReminderContent from "../header/ReminderContent";

SwiperCore.use([Pagination, Autoplay]);

export default function SwiperSlideHeader({images}) {
    return (
        <div className="position-relative header-slider">
            <HeaderAbout/>
            <ReminderContent/>
            <Swiper
                className="swiper-header-container"
                spaceBetween={0}
                autoplay={{
                    delay: 5500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
            >
                {images
                    ?.filter((img) => img.isActive)
                    .map(({url, title, id}) => (
                        <SwiperSlide key={id}>
                            <Image
                                className="w-100 carousel__img"
                                src={url}
                                alt={title}
                                fluid
                                loading="progressive"
                            />
                        </SwiperSlide>
                    ))}{" "}
            </Swiper>{" "}
        </div>
    );
}
