import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, Pagination} from "swiper";
import "swiper/swiper-bundle.css";
import {Image} from "react-bootstrap";

SwiperCore.use([Pagination, Autoplay]);

export default function Publicity() {
    return (
        <div className='mt-5 pt-4 mb-4'>
            <Swiper
                spaceBetween={40}
                slidesPerView={4}
                autoplay={{
                    delay: 6000,
                    disableOnInteraction: false,
                }}
            >
                <SwiperSlide>
                    <Image src='/images/publicity__img_3.png' alt='publicity1' className="publicity-img"/>
                </SwiperSlide>
                <SwiperSlide>
                    <Image src='/images/publicity__img_3.png' alt='publicity1' className="publicity-img"/>
                </SwiperSlide>
                <SwiperSlide>
                    <Image src='/images/publicity__img_3.png' alt='publicity1' className="publicity-img"/>
                </SwiperSlide>
                <SwiperSlide>
                    <Image src='/images/publicity__img_3.png' alt='publicity1' className="publicity-img"/>
                </SwiperSlide>
                <SwiperSlide>
                    <Image src='/images/publicity__img_3.png' alt='publicity1' className="publicity-img"/>
                </SwiperSlide>
                <SwiperSlide>
                    <Image src='/images/publicity__img_3.png' alt='publicity1' className="publicity-img"/>
                </SwiperSlide>
                <SwiperSlide>
                    <Image src='/images/publicity__img_3.png' alt='publicity1' className="publicity-img"/>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}
