import React from 'react';
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

function BackTo() {
    const navigate = useNavigate();
    return (
        <Button onClick={() => navigate(-1)} variant="outline-danger" className="d-flex w-auto mb-lg-4">
            Retourner
        </Button>
    );
}

export default BackTo;