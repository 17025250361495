import React, {useEffect, useState} from "react";
import Advantage from "./Advantage";
import TopAppartment from "./TopAppartment";
import "./home.css";
import Publicity from "./Publicity";
import Parteners from "../parteners/Parteners";
import {useSelector} from "react-redux";
import {addViewCollection} from "../../../firebase/config";
import TopProduct from "./TopProduct";

export default function Home() {
    const {
        flatData: {data},
        view: {views},
        partners: {partners},
        mode: {modes},
        game: {games},
        collection
    } = useSelector((state) => state);
    const [viewData] = useState(views);
    const [rentHouseData, setRentHouseData] = useState([]);
    const [rentOfficeData, setRentOfficeData] = useState([]);
    const [saleLandData, setSaleLandData] = useState([]);
    const [beautyData, setBeautyData] = useState([])

    const handleClickView = (id) => {
        let count =
            (viewData && viewData?.find((v) => v?.id === id)?.countView) || 0;
        let incremntCount = ++count;
        addViewCollection(id, incremntCount);
    };

    useEffect(() => {
        let isCancelled = false;
        (async () => {

            if (!isCancelled) {
                const articles = await collection
                const filterData = (category) =>
                    data?.filter((d) => d?.category === category)?.slice(0, 4);
                setBeautyData(articles?.data?.slice(0, 4))
                setRentHouseData(filterData("Maison à louer"));
                setRentOfficeData(filterData("Bureau à louer"));
                setSaleLandData(filterData("Terrain à vendre"));
            }
        })()
        return () => {
            isCancelled = true;
        }
    }, [data, collection]);

    return (
        <div>
            <TopProduct data={modes} type="Mode" link="/products/Mode"/>
            <TopProduct data={beautyData} type="Beauté, hygiène et Parfum" link="/products/beauty-and-parfum"/>
            <TopAppartment
                data={rentHouseData}
                handleClickView={handleClickView}
                type="Maison à louer"
            />
            <TopProduct
                data={games}
                type="Jeux et Jouets"
                link="/products/toy-and-game"
            />
            <TopAppartment
                data={rentOfficeData}
                handleClickView={handleClickView}
                type="Bureau à louer"
            />
            <TopAppartment
                data={saleLandData}
                handleClickView={handleClickView}
                type="Terrain à vendre"
            />
            <Advantage/>
            <Publicity/>
            <Parteners partners={partners}/>
        </div>
    );
}
