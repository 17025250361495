import {combineReducers, configureStore} from "@reduxjs/toolkit";
import dataReducer from "../toolkit/data/dataSlice";
import detailReducer from "../toolkit/data/detailItemSlice";
import viewReducer from "../toolkit/data/viewSlice";
import scrollingImageReducer from "../toolkit/data/scrollingImageSlice";
import PartnerReducer from "../toolkit/data/partnerSlice";
import AddToCartReducer from "../toolkit/data/addToCartSlice";
import modeReducer from "../toolkit/data/modeSlice";
import gameReducer from "../toolkit/data/gameSlice";
import CollectionReducer from '../toolkit/data/findCollectionSlice'
import ArticleByCategoryReducer from '../toolkit/data/findByCategorySlice'
import {persistStore} from "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import MagnifierReducer from "../toolkit/data/magnifierSlice"

const rootReducer = combineReducers({
    flatData: dataReducer,
    detailFlat: detailReducer,
    view: viewReducer,
    images: scrollingImageReducer,
    partners: PartnerReducer,
    addToCart: AddToCartReducer,
    mode: modeReducer,
    game: gameReducer,
    byCategory: ArticleByCategoryReducer,
    magnifier: MagnifierReducer,
    collection: CollectionReducer
});

const persistConfig = {
    key: "root",
    storage,
    blacklist: [
        "flatData",
        "detailFlat",
        "view",
        "images",
        "partners",
        "game",
        "mode",
        "byCategory",
        "collection"
    ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
