import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchFlatDataAsync} from "./fetchData";

export const gePartnerDataAsync = createAsyncThunk("fetchPartnerData", async () => {
    const partners = await fetchFlatDataAsync("partners")
    return [...partners];
});

const initialState = {
    status: "idle",
    partners: [],
};

export const partnerSlice = createSlice({
    name: "partners",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(gePartnerDataAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(gePartnerDataAsync.fulfilled, (state, action) => {
                state.partners = action.payload;
                state.status = "idle";
            });
    },
});

export default partnerSlice.reducer;