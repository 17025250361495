export const ECommerceContent = ({data, handleClickAddToCart, handleBuyBtn}) => {
    return <>
        <h4 className="text-primary text-end mt-2 mb-lg-4">
            {data?.sub_category}
        </h4>
        <div className="detail-product-content">
            <p className="text-muted fw-bold">{data?.productName}</p>
            <p>{data?.brand}</p>
        </div>
        <div className="detail-product-content">
            {data?.bestSeller === "true" && (
                <div className="detail-sale">
                    <p className="top-sale">meilleures ventes</p>
                </div>
            )}
            <p className="text-black-50 fw-bold">{data?.price * (1 - data?.discount / 100)} XAF</p>
            {Boolean(+data?.discount) && (
                <p className="small-text d-flex gap-1">
                    <span className="discount">{data?.discount} %</span>
                    <span className="initial-price">{data?.price} XAF</span>
                </p>
            )}
            <div>
                <p>Les couleurs disponibles</p>
                <div className="d-flex gap-3">
                    {data?.colors?.map((c, idx) => (
                        <p
                            key={idx}
                            style={{background: "#" + c}}
                            className="color-product"
                        ></p>
                    ))}
                </div>
            </div>
        </div>
        <div className="detail-product-content">
            <p>{data?.size.toUpperCase()}</p>
            {
                data?.sub_products.map((s, idx) => <p key={idx}>{s}</p>)
            }
        </div>
        <div className="detail-product-about">
            <h4>À propos de cet article</h4>
            <ul>
                {data?.aboutProducts?.map((a, idx) => (
                    <li key={idx}>{a}</li>
                ))}
            </ul>
        </div>
        <h3 className="text-black text-end detail-product-price">
            <span>Prix à payer : </span>
            {data?.price * (1 - data?.discount / 100)} XAF
        </h3>
        <div className="text-center btn-buy-content">
            <button
                onClick={handleClickAddToCart}
                className="btn-buy add-product"
            >
                Ajouter au panier
            </button>
            <button className="btn-buy" onClick={handleBuyBtn}>
                Achetez maintenant
            </button>
        </div>
    </>
}