import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import SecurityIcon from "@mui/icons-material/Security";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ConstructionIcon from "@mui/icons-material/Construction";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export default function Advantage() {
    return (
        <Container fluid className='advantage__container'>
            <Row className='mt-md-5 mt-3'>
                <h2 className='text-center'>
                    Les avantages de travailler avec Lefleuve
                </h2>
            </Row>
            <Row className='gap-md-4 gap-sm-3 gap-3 mt-md-5 mt-2 d-flex justify-content-center'>
                <Col sm={4} md={4} lg={3} className='advantage__col'>
                    <div className='text-center'>
                        <ThumbsUpDownIcon fontSize='large'/>
                    </div>
                    <h4>ÉCONOMIES ET AVANTAGES</h4>
                    <p className='advantage__text small-text'>
                        Dans notre travail, nous nous efforçons de satisfaire les souhaits
                        de toutes les parties à la transaction. En raison de la large base
                        de biens immobiliers, nous offrons les meilleures options en termes
                        de rapport qualité / prix. Avec nous, vous économisez votre temps et
                        vos efforts,
                    </p>
                </Col>
                <Col sm={4} md={4} lg={3} className='advantage__col'>
                    <div className='text-center'>
                        <SecurityIcon fontSize='large'/>
                    </div>
                    <h4>SÉCURITÉ</h4>
                    <p
                        className='advantage__text small-text'>
                        Nous avons toujours des informations à jour sur les prix. Nous
                        préparons tous les documents nécessaires à la transaction. Nous ne
                        coopérons pas avec des intermédiaires et des agents immobiliers à la
                        réputation douteuse.
                    </p>
                </Col>
                <Col sm={4} md={4} lg={3} className='advantage__col'>
                    <div className='text-center'>
                        <PublishedWithChangesIcon fontSize='large'/>
                    </div>
                    <h4>FIABILITÉ</h4>
                    <p className='advantage__text small-text'>
                        Nous fournissons une assistance juridique qualifiée. Si nécessaire,
                        nous effectuons la préparation des documents dans les plus brefs
                        délais. Nous contrôlons le plein pour l'appartement. Nous
                        garantissons la confidentialité de chaque transaction. Paiement
                    </p>
                </Col>
                <Col sm={4} md={4} lg={3} className='advantage__col'>
                    <div className='text-center'>
                        <LibraryBooksIcon fontSize='large'/>
                    </div>
                    <h4>ATTENTION AUX DÉTAILS</h4>
                    <p className='advantage__text small-text'>
                        Nous écoutons les souhaits de chaque client, voulant sincèrement
                        aider. Nous abordons une tâche de toute complexité avec compétence
                        et stratégie correctement, car nous représentons vos intérêts
                        financiers, et le succès du client est notre succès.
                    </p>
                </Col>
                <Col sm={4} md={4} lg={3} className='advantage__col'>
                    <div className='text-center'>
                        <ConstructionIcon fontSize='large'/>
                    </div>
                    <h4>DIFFICULTÉ SUR NOUS</h4>
                    <p className='advantage__text small-text'>
                        Nos experts réalisent une expertise compétente et indépendante du
                        logement. Ils prennent également en charge l'ensemble du cycle de
                        travail, de la recherche d'offres en cours sur le marché immobilier
                        jusqu'à l'exécution compétente de la documentation juridique.
                    </p>
                </Col>
                <Col sm={4} md={4} lg={3} className='advantage__col'>
                    <div className='text-center'>
                        <CalendarTodayIcon fontSize='large'/>
                    </div>
                    <h4>RAPIDITÉ ET COMMODITÉ</h4>
                    <p className='advantage__text small-text'>
                        L'expérience et les connaissances nous aident à résoudre n'importe
                        quel problème dans les plus brefs délais. Afin de vous simplifier au
                        maximum le processus d'achat ou de vente, nous vous proposons une
                        inscription rapide clé en main, ainsi que des offres exclusives «
                        ici et maintenant ».
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
